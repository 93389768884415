/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/graphs/gra008.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-7 ls-1'>Pages</span>
        </div>
      </div>

      <SidebarMenuItem to='/create/version' title='Create Version' icon='/media/icons/duotune/files/fil005.svg' /> 
      <SidebarMenuItem to='/view/version' title='View Versions' icon='/media/icons/duotune/general/gen005.svg' /> 
      <SidebarMenuItem to='/do/release' title='Do Release' icon='/media/icons/duotune/files/fil005.svg' />
      <SidebarMenuItem to='/view/release' title='View Release' icon='/media/icons/duotune/general/gen005.svg' /> 
      <SidebarMenuItem to='/view/files' title='View Files' icon='/media/icons/duotune/files/fil005.svg' /> 

    </>
  )
}

export {SidebarMenuMain}
