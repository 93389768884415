import { Component } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "react-datetime/css/react-datetime.css";
import Moment from "moment";
import { AUTH_LOCAL_STORAGE_KEY } from "../../app/modules/auth";
import { deleteS3File } from "../models/_viewFileList";
import toast, { Toaster } from "react-hot-toast";

class ViewFiles extends Component<any, any> {
  readonly props: any;
  constructor(props: any) {
    super(props);
    this.state = {
      fileList: [],
      deleteFileModalOpen: false,
      deleteS3FileKey:null,
      loading: false,
      limit: 50,
      page: 0,
      per_page: 50,
      nextStartAfter:null
    };
  }

  async componentWillMount() {}
  async componentDidMount() {
    var _ = this;
    $("#updateTable").on("click", ".deleteFileBtn", function () {
        const deleteS3FileKey: any = $(this).attr("file-key");
      _.deleteFileModalOpen(deleteS3FileKey);
    });
    this.renderDataTable();
  }
  async componentDidUpdate() {}

  renderDataTable = () => {
    var _this = this;
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    $(document).ready(function () {
      $("#updateTable").DataTable().destroy();
      let table = $("#updateTable").DataTable({
        "ajax": {
          url: process.env.REACT_APP_API_URL,
          type: "POST",
          contentType: "application/json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + authToken);
          },
          "data": function (d: any) {
            var gql = ` query getS3FileList {
                getS3FileList {
                  data {
                    fileName
                    createdAt
                  }
                  total
                 }
              }`;
            var query = {
              operationName: null,
              query: gql
            };

            return JSON.stringify(query);
          },
         "dataSrc": function (json) {
            json.recordsTotal = json.data.getS3FileList.total;
            json.recordsFiltered = json.data.getS3FileList.total;

            let counter = 0;
            const rows = [];
            const records = json.data.getS3FileList.data;
            _this.setState({
              nextStartAfter:json.data.getS3FileList.nextContinuationToken
            })
            for (const record of records) {
              counter++;

              const tempRecord = {
                id: counter,
                fileName: record.fileName,
                createdAt: Moment.utc(record.createdAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss"),
              };
              rows.push(tempRecord);
            }
            _this.setState({
              fileList: rows,
            });
            return rows;
          },
        },
        "initComplete": function (settings, json) {
          table.page(Number(_this.state.page)).draw(false);
        },
        columns: [
          {
            name: "fileName",
            render: function (data, type, row) {
              return row.fileName;
            },
          },
          {
            name: "Actions",
            render: function (data, type, row) {
              return '<button class="btn btn-danger btn deleteFileBtn m-1 p-2 px-4" data-bs-toggle="tooltip" file-key=\'' + row.fileName + "'>Delete</button>";
            },
          },
        ],
        pagingType: "full_numbers",
        paging: true,
        pageLength: _this.state.limit,
        processing: true,
        serverSide: false,
        searching:false,
        dom: "Bfrtip",
        destroy: true,
        order: [[1, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
      });
      table.on("page", function () {
        const currentPage = table.page.info();
        _this.setState({
          page: currentPage.page,
        });
      });
      $(document)[0].oncontextmenu = function () {
        return false;
      };
    });
  };

  deleteFileModalOpen = (deleteS3FileKey:string) => {
    this.setState({
      deleteFileModalOpen: true,
      deleteS3FileKey: deleteS3FileKey
    });
  };

  deleteFileModalClose = () => {
    this.setState({
      deleteFileModalOpen: false,
    });
  };

  handleDeleteS3File = async () => {
    this.setState({loading:true})
    const s3FileKey = this.state.deleteS3FileKey;
    const isDeleted = await deleteS3File(s3FileKey);

    if(!isDeleted){
       toast.error("Failed to delete the file. Please try again.")
    }

    this.deleteFileModalClose();
    this.setState({ loading:false})
    this.renderDataTable();
    toast.success("File deleted successfully!")

  }

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                View Files
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">View Files</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="updateTable"
                    className="responsive-table table display align-items-center justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          File Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Actions
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.deleteFileModalOpen}
          onClose={this.deleteFileModalClose}
          role="dialog"
          center
        >
          <div className="modal-header p-4">
            <h4 className="modal-title fs-3">Delete File</h4>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <p className="m-4">Are you sure you want to delete this file?</p>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-between p-4">
            <button
              type="button"
              className="btn btn-secondary fs-5"
              onClick={this.deleteFileModalClose}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.handleDeleteS3File}
              className="btn btn-danger fs-5"
            >
              {this.state.loading ? (
                <span className="d-flex align-items-center">
                  Please Wait...
                </span>
              ) : (
                <span>Delete</span>
              )}
            </button>
          </div>
        </Modal>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </>
    );
  }
}

export default ViewFiles;