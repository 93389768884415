import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function deleteS3File(
    fileName:string
  ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
          mutation deleteS3File ($fileName: String!){
              deleteS3File(
                  input: {
                    fileName: $fileName
                  }
              ) 
            }
          `,
        variables: {
          fileName
        },
      },
    })
    return data?.data?.data?.deleteS3File;
  }